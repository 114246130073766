.about{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 100px 150px 10px 150px;
}
.about-intro{
    display: flex;
    flex-direction: row;
}
.hero-img img {
    max-width: 100%; /* This will make the image's width no larger than the width of the container */
    height: 100%; /* This will make the image's height scale proportionally with the width */
    object-fit: contain; /* This will make the image fit within the container without stretching */
    padding: 0 25px;
}
.about-subheading{
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    text-align: left;
    line-height: 1.8rem;
    max-width: 700px;
}
.download-resume{
    border: none;
    outline: none;
    padding: 15px;
    font-size: 20px;
    color: white;
    background-color: #506056;
    margin: 20px 0 20px 0;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
}
.download-resume:hover{
    box-shadow: 0 1px 3px rgb(0 0 0 / 34%), 0 1px 2px rgb(0 0 0 / 64%);
    cursor: pointer;
}


@media only screen and (min-width: 551px) and (max-width: 1050px) {
    .about{
        margin: 80px 25px 25px 25px;
    }
    .about-intro{
        display: flex;
        flex-direction: column;
    }
    .about-subheading{
        font-size: 15px;
    }
    .download-resume{
        padding: 15px 5px;
        font-size: 16px;
    }
}
@media only screen and (min-width: 100px) and (max-width: 550px) {
    .about{
        margin: 70px 10px 10px 10px;
    }
    .about-intro{
        display: flex;
        flex-direction: column;
    }
    .hero-img img {
        display: none;
    }
    .about-subheading{
        font-size: 14px;
        max-width: 500px;
    }
    .download-resume{
        padding: 15px 5px;
        font-size: 16px;
    }
}