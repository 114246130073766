
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300&family=ZCOOL+XiaoWei&display=swap');

.case-study-banner{
    padding: 80px 180px 0 180px;
    display: flex;
    gap: 1px;
    align-items: center;
    width: 100%;
}
.rest{
    background-color: #506056;
}
.dap{
    background: #6E85F7;
}
.deen{
    background-color: #EC652C;
}
.design{
    background-color: #43B8D3;
}
.case-study-left {
    flex: 1;
    padding-right: 24px;
}
.case-study-right {
    flex: 1;
    padding: 15px;
}
.case-study-right img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
}
.case-study-title{
    font-family: 'Open Sans', sans-serif;
    font-size: 46px;
    text-align: left;
    color: white;
    font-weight: bold;
    margin: 0 0 25px 0;
}
.case-study-brief{
    font-family: 'Open Sans', sans-serif;
    font-size: 22px;
    text-align: left;
    color: white;
    font-weight: 350;
    line-height: 2.1rem;
}

.project-container{
    display: flex;
    margin: 0 225px 0 225px;
}
.side-bar{
    width: 22%;
    float: left;
    position: sticky;
    top: 45px;
    height: calc(100vh - 60px); /* subtract 60px for top and bottom margins */
    z-index: 1000;
    overflow-y: auto;
    padding-right: 20px;
    padding-top: 1.8rem;
}
.project-content{
    /*max-width: 900px;*/
    /*width: 100%;*/
    /*float: right;*/
    text-align: left;
    color: #37352f;
    overflow-y: auto;
    overflow-x: hidden;
    justify-content: center;
}
.project-heading{
    font-family: 'ZCOOL XiaoWei', sans-serif;
    font-size: 2.5rem;
    color: #37352f;
    margin: 0 0 25px 0;
}
.project-subheading{
    font-family: 'ZCOOL XiaoWei', sans-serif;
    font-size: 1.7rem;
    margin: 60px 0 15px 0;
    color: #37352f;
}
.project-des{
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    margin: 5px 5px 5px 0;
    color: #37352f;
    line-height: 2rem;
    font-weight: 300;
    max-width: 800px;
}
.project-des li{
    font-size: 18px;
    margin: 5px 5px 10px 25px;
    color: #37352f;
    line-height: 2rem;
    list-style-type: disc;
    max-width: 800px;
}
.project-des li span{
    font-weight: 510;
}

.two-rows-des{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr) );
    column-gap: 8px;
}
.two-rows{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr) );
    column-gap: 25px;
    row-gap: 25px;
}
.three-rows{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr) );
    column-gap: 25px;
    row-gap: 25px;
}
.four-rows{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr) );
    column-gap: 25px;
    row-gap: 25px;
    padding: 20px;
    background-color: red;
}
.project-row-header{
    /*border: 1px solid grey;*/
    box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
    border-radius: 5px;
    margin: 2px;

}
.project-row-header h3{
    background-color: #506056;
    color: white;
    font-size: 1.3rem;
    font-weight: 600;
    text-align: center;
    padding: 10px 0 5px 30px;
    box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
    border-radius: 5px 5px 0 0;

}
.project-row-header p{
    font-size: 1rem;
    line-height: 1.8rem;
    text-align: left;
    padding: 10px;
}
h3{
    color: #37352f;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 10px 0 5px 0;
}
.image01{
    padding: 35px 15px;
}
.image01 img {
    max-width: 100%; /* This will make the image's width no larger than the width of the container */
    height: auto; /* This will make the image's height scale proportionally with the width */
    object-fit: contain; /* This will make the image fit within the container without stretching */
}
.container {
    display: flex;
    width: 100%; /* Make the container take up the full width of the case study section */
    overflow-x: hidden; /* Prevent horizontal scrolling */
    gap: 250px;
}
.containerDeen {
    display: flex;
    width: 100%; /* Make the container take up the full width of the case study section */
    overflow-x: hidden; /* Prevent horizontal scrolling */
    justify-content: space-between;
}
.image-container{
    flex: 1; /* Make each image and text container take up equal amount of space */
    display: flex;
    align-items: center; /* Vertically center the contents of each container */
    padding: 5px;
}
.image25 {
    max-width: 90%; /* Make the images fit within the container */
    height: auto;
}



.table-of-contents{
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-top: 20px;
}
.table-of-contents h2{
    font-family: "Open Sans", sans-serif;
    font-size: 24px;
    font-weight: 600;
    color: #37352f;
    padding: 0 0 8px 0;
}
.table-of-contents .content-items{
    padding: 6px 0 6px 0;
}
.table-of-contents .content-title{
    font-family: "Open Sans", sans-serif;
    font-size: 20px;
    font-weight: 500;
    color: #3285F4;
}
.table-of-contents .content-subitem{
    padding: 3px 0 3px 0;
}
.table-of-contents .content-subitem a{
    font-size: 16px;
    color: #37352f;
}
.table-of-contents .hidden {
    display: none;
}

@media only screen and (min-width: 851px) and (max-width: 1050px){
    .case-study-banner{
        padding: 80px 100px 25px 100px;
    }
    .case-study-left {
        padding-right: 25px;
    }
    .case-study-right {
        padding: 0;
    }
    .case-study-right img {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
    }
    .case-study-title{
        font-size: 36px;
        margin: 0 0 2px 0;
    }
    .case-study-brief{
        font-size: 17px;
        line-height: 1.8rem;
    }

    .project-container{
        margin: 25px 100px 25px 50px;
    }
    .side-bar{
        width: 20%;
    }
    .project-content{
        width: 75%;
    }
    .project-heading{
        font-size: 30px;
        margin: 0 0 20px 0;
    }
    .project-subheading{
        font-size: 26px;
        margin: 25px 0 10px 0;
    }
    .project-des{
        font-size: 16px;
        line-height: 1.8rem;
    }
    .project-des li{
        margin: 5px 5px 2px 20px;
        font-size: 16px;
        line-height: 1.8rem;
    }
    .project-des li span{
        font-weight: 300;
    }
    h3{
        font-size: 22px;
        font-weight: 500;
    }


    .two-rows-des{
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr) );
        column-gap: 0;
        row-gap: 0;
    }
    .two-rows{
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(350px, 1fr) );
        column-gap: 0;
        row-gap: 0;
    }
    .three-rows{
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(210px, 1fr) );
        column-gap: 0;
        row-gap: 0;
    }


    .container {
        gap: 35px;
    }
    .image-container{
        padding: 10px;
    }
    .image25 {
        max-width: 100%;
        height: 100%;
    }

    .table-of-contents h2{
        font-size: 20px;
        font-weight: 600;
    }
    .table-of-contents .content-title{
        font-family: "Open Sans", sans-serif;
        font-size: 15px;
    }
}
@media only screen and (min-width: 551px) and (max-width: 850px){
    .case-study-banner{
        padding: 80px 15px 15px 15px;
    }
    .case-study-left {
        padding-right: 10px;
    }
    .case-study-right {
        padding: 0;
    }
    .case-study-right img {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
    }
    .case-study-title{
        font-size: 26px;
        margin: 0 0 2px 0;
    }
    .case-study-brief{
        font-size: 15px;
        line-height: 1.2rem;
    }

    .project-container{
        margin: 25px;
    }
    .side-bar{
        display: none;
    }
    .project-content{
        width: 100%;
    }
    .project-heading{
        font-size: 28px;
        margin: 0 0 20px 0;
    }
    .project-subheading{
        font-size: 24px;
        margin: 25px 0 10px 0;
    }
    .project-des{
        font-size: 16px;
        line-height: 1.8rem;
    }
    .project-des li{
        margin: 5px 5px 2px 20px;
        font-size: 16px;
        line-height: 1.8rem;
    }
    .project-des li span{
        font-weight: 300;
    }
    h3{
        font-size: 22px;
        font-weight: 500;
    }


    .two-rows-des{
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr) );
        column-gap: 0;
        row-gap: 0;
    }
    .two-rows{
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(350px, 1fr) );
        column-gap: 0;
        row-gap: 0;
    }
    .three-rows{
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr) );
        column-gap: 0;
        row-gap: 0;
    }


    .container {
        gap: 35px;
    }
    .image-container{
        padding: 10px;
    }
    .image25 {
        max-width: 100%;
        height: 100%;
    }
}
@media only screen and (min-width: 100px) and (max-width: 550px){
    .case-study-banner{
        padding: 70px 15px 15px 15px;
    }
    .case-study-left {
        padding-right: 5px;
    }
    .case-study-right {
        padding: 0;
    }
    .case-study-right img {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
    }
    .case-study-title{
        font-size: 16px;
        margin: 0 0 2px 0;
    }
    .case-study-brief{
        font-size: 12px;
        line-height: 1.2rem;
    }

    .project-container{
        margin: 10px;
    }
    .side-bar{
        display: none;
    }
    .project-content{
        width: 100%;
    }
    .project-heading{
        font-size: 26px;
        margin: 0 0 20px 0;
    }
    .project-subheading{
        font-size: 22px;
        margin: 25px 0 10px 0;
    }
    .project-des{
        font-size: 15px;
        line-height: 1.8rem;
    }
    .project-des li{
        margin: 5px 5px 2px 20px;
        font-size: 15px;
        line-height: 1.8rem;
    }
    .project-des li span{
        font-weight: 300;
    }
    h3{
        font-size: 20px;
        font-weight: 500;
    }


    .two-rows-des{
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr) );
        column-gap: 0;
        row-gap: 0;
    }
    .two-rows{
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(350px, 1fr) );
        column-gap: 0;
        row-gap: 0;
    }
    .three-rows{
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr) );
        column-gap: 0;
        row-gap: 0;
    }


    .container {
        gap: 15px;
    }
    .image-container{
        padding: 5px;
    }
    .image25 {
        max-width: 100%;
        height: 100%;
    }
}



.video-walk{
    margin: 25px 0 50px 0;
    display: flex;
    justify-content: center;
}
.video-walk video{
    width: 90%;
    height: 90%;
    object-fit: cover;
    border: 1px solid darkgray;
    border-radius: 5px;
}