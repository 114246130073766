
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300&family=ZCOOL+XiaoWei&display=swap');


:root{
  /* ----- Colors ----- */
  --body-color-light:white;
  --body-color-dark:black;
  --color-grey:grey;
  --color-blush:#FBEAE3;
  --color-levender:#E4DBEA;
  --color-blue:#E0EDFC;
  --color-green:#DFF5F1;
  --color-levender2:#DFF5F1;
  --color-yellow:#F9F6CC;

  /* ----- Fonts ----- */
  --small-body:16px;
  --mid-body:24px;
  --large-body:32px;
  --xlarge-body:55px;
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
html,body{
  height: 100%;
  /*width: 100%;*/
  overflow-x: hidden;
  scroll-behavior: smooth;
  scroll-padding-top: 60px;
}
.App {
  justify-content: center;
  align-items: center;
  text-align: center;
  /*margin: 0 150px 50px 150px;*/
  /*margin-top: 50px;*/
}
ul{
  list-style: none;
}
a{
  text-decoration: none;
}
.section{
  padding: 0;
  margin: 0;
}

@media only screen and (min-width: 330px) and (max-width: 550px){

}
@media only screen and (min-width: 551px) and (max-width: 650px){

}
@media only screen and (min-width: 651px) and (max-width: 750px){

}
@media only screen and (min-width: 751px) and (max-width: 1200px){

}
