.navbar {
  display: flex;
  position: fixed;
  height: 10vh;
  justify-content: center;
  align-items: center;
  top: 0;
  z-index: 999;
  width: 100%;
  background: transparent;
  margin: 0;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 200px 0 200px;
  width: 100%;
}

.navbar-menu {
  display: flex;
  align-items: center;
}

.navbar-link {
  display: block;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 7px 15px;
  color: #37352f;
  background: white;
  outline: none;
  border: 0.5px solid #37352f;
  border-radius: 25px;
  transition: 0.5s;
  margin: 10px;
  font-weight: 500;
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
}

.navbar-link:hover, .navbar-link:focus {
  background-color: #37352f;
  color: white;
}

.navbar-link.active {
  background-color: #37352f;
  color: white;
}

.logo {
  display: flex;
  justify-content: center;
  align-content: center;
  margin: 25px 0;
}

.logo-text img {
  width: 60px;
  height: 60px;
  object-fit: contain;
}

.social-i {
  font-size: 30px;
  justify-content: center;
  align-content: center;
  margin: 10px;
  color: #37352f;
}

.mobile-menu-icon {
  display: none;
}

@media only screen and (min-width: 200px) and (max-width: 950px) {
  .navbar {
    height: 8vh;
    background: white;
  }

  .navbar.active {
    box-shadow: none;
  }

  .navbar-menu {
    display: none;
  }

  .logo {
    display: flex;
    position: absolute;
    top: 18px;
    left: 15px;
    margin: 0;
    white-space: nowrap;
    justify-content: center;
    align-content: center;
  }

  .logo-text img {
    width: 35px;
    height: 35px;
    object-fit: contain;
  }

  .navbar-menu-mobile {
    position: absolute;
    display: block;
    left: 0;
    top: 55px;
    transition: all 0.5s ease-in-out;
    width: 100%;
    background: white;
  }

  .mobile-menu-icon {
    display: block;
    position: absolute;
    font-size: 25px;
    color: black;
    border: none;
    outline: none;
    background: none;
    top: 50%;
    right: 5%;
    transform: translateY(-50%);
  }
}

