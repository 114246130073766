.footer {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    display: flex;
    flex-direction: row;
    bottom: 0;
    width: 100%;
    color: var(--body-color-dark);
    padding: 60px 150px 20px 150px;
    justify-content: space-between;
}
.footer ul{
    display: flex;
    flex-direction: row;
}
.footer ul li{
    align-items: center;
    justify-content: center;
    padding: 5px 15px;
    color: rgb(55, 53, 47);
    background: white;
    outline: none;
    border: 0.5px solid rgb(55, 53, 47);
    border-radius: 25px;
    transition: 0.5s;
    margin: 10px;
}
.footer ul li:hover, .footer ul li:focus{
    background-color: rgb(55, 53, 47);
}
.footer ul li a span:hover, .footer ul li a span:focus{
    color: white;
}
.footer ul li a span{
    font-weight: 400;
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
}

@media only screen and (min-width: 100px) and (max-width: 550px) {
    .footer {
        flex-direction: column;
        bottom: 0;
        justify-content: center;
        padding: 25px 0;
    }
    .footer ul{
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .footer ul li a span{
        font-weight: 400;
        font-family: 'Open Sans', sans-serif;
        font-size: 15px;
    }
}