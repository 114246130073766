@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300&family=ZCOOL+XiaoWei&display=swap');

.home-container{
    display: flex;
    flex-direction: column;
    margin: 80px 200px 0 200px;
}
.hero{
    display: grid;
    grid-template-columns: 50% 50%;
    justify-content: space-between;
    align-items: center;
}
.hero-left{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    margin-right: 50px;
}
.homeheader{
    font-family: 'ZCOOL XiaoWei', sans-serif;
    font-size: 40px;
    line-height: 3.0rem;
    max-width: 800px;
    justify-content: left;
    text-align: left;
    font-weight: 400;
    padding: 0 0 25px 0;
}
.hero-right img{
    height: 85%;
    width: 85%;
    border-radius: 50%;
    padding: 45px;
}
.direction{
    font-size: 1rem;
    font-weight: 300;
    justify-content: center;
}
.languages{
    padding: 10px 0 0 0;
    display: grid;
    grid-template-columns: repeat(7, minmax(50px, 1fr));
    row-gap: 5px;
    column-gap: 5px;
}
.skill-icon{
    padding: 0 5px 0 10px;
    display: flex;
    flex-direction: column;
    /*border-radius: 50%;*/
    /*background: #34a1cd;*/
}
.skill-icon img{
    width: 35px;
    height: 35px;
    margin: 5px;
    cursor: pointer;
    /*transition: 0.2s;*/
    /*border-radius: 5px;*/
}
.skill-icon img:hover{
    /*box-shadow: 0 1px 3px rgb(0 0 0 / 34%), 0 1px 2px rgb(0 0 0 / 64%);*/
}
.skill-icon p{
    justify-content: center;
    text-align: center;
    font-size: 14px;
    font-weight: 300;
    color: grey;
}

.to-casestudy{
    display: flex;
    flex-direction: row;
    justify-content: left;
    text-align: left;
    margin: 25px 0;
}
.to-casestudy p{
    font-family: 'ZCOOL XiaoWei', sans-serif;
    font-size: 25px;
    padding-right: 22px;
    text-align: left;
    justify-items: left;
}
.projects{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr) );
    column-gap: 100px;
    row-gap: 100px;
}
.projects-min{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr) );
    column-gap: 35px;
    row-gap: 35px;
    border-radius: 8px 8px 0 0;
    margin: 5px 2px 20px 2px;
}
.projects-min .banner-image{
    border-radius: 8px 8px 0 0;
}
.projects-min .project{
    border-radius: 8px;
}
.project{
    display: flex;
    flex-direction: column;
    /*border: 1px solid lightgrey;*/
    /*box-shadow: 0 1px 3px rgb(0 0 0 / 10%), 0 1px 2px rgb(0 0 0 / 10%);*/
    border: 1px solid #dadce0;
    border-radius: 8px;
    padding: 0 0 15px 0;
}
.banner-image{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px 8px 0 0;
}
.project-info{
    display: flex;
    flex-direction: column;
    font-family: 'Open Sans', sans-serif;
    text-align: left;
    padding: 5px 15px;
}
.project-title{
    font-family: 'Open Sans', sans-serif;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 10px;
    color: rgb(55, 53, 47);
}
.project-brief{
    font-size: 15px;
    font-weight: 400;
    line-height: 1.4rem;
    margin-bottom: 10px;
    color: rgb(55, 53, 47);
}
.project-link{
    display: flex;
    flex-direction: row;
    width: fit-content;
    justify-content: center;
    padding: 10px 15px;
    color: rgb(55, 53, 47);
    outline: none;
    border: 0.5px solid rgb(55, 53, 47);
    border-radius: 25px;
    transition: 0.5s;
}
.project-link:hover, .project-link:focus{
    background-color: rgb(55, 53, 47);
    color: white;
}
.button-text{
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    font-size: 16px;
}

.playground-header-home{
    display: flex;
    text-align: center;
    justify-content: center;
    font-size: 3.5rem;
    font-weight: 700;
    margin: 100px 150px 10px 150px;
    color: rgb(55, 53, 47);
}


@media only screen and (min-width: 851px) and (max-width: 1050px) {
    .home-container{
        margin: 100px 100px 15px 100px;
    }
    .hero{
        display: grid;
        grid-template-columns: 60% 40%;
    }
    .hero-left{
        margin-right: 10px;
    }
    .homeheader{
        font-size: 40px;
        max-width: 500px;
    }
    .hero-right img{
        height: 95%;
        width: 95%;
        border-radius: 50%;
        padding: 5px;
    }
    .languages{
        grid-template-columns: repeat(7, minmax(50px, 1fr));
    }

    .to-casestudy p{
        font-size: 24px;
        padding-right: 15px;
    }
    .projects{
        column-gap: 35px;
        row-gap: 35px;
    }
    .project-title{
        font-size: 22px;
    }
    .project-brief{
        font-size: 15px;
        line-height: 1.8rem;
    }
}
@media only screen and (min-width: 551px) and (max-width: 850px) {
    .home-container{
        margin: 80px 25px 15px 25px;
    }
    .hero{
        display: grid;
        grid-template-columns: 1fr;
    }
    .hero-left{
        margin-right: 0;
    }
    .homeheader{
        font-size: 36px;
        max-width: 500px;
    }
    .hero-right img{
        display: none;
    }
    .languages{
        grid-template-columns: repeat(7, minmax(50px, 1fr));
    }

    .to-casestudy p{
        font-size: 20px;
        padding-right: 10px;
    }

    .project-title{
        font-size: 22px;
    }
    .project-brief{
        font-size: 16px;
        line-height: 1.8rem;
    }
}
@media only screen and (min-width: 100px) and (max-width: 550px) {
    .home-container{
        display: flex;
        flex-direction: column;
        margin: 70px 15px 15px 15px;
    }
    .hero{
        display: grid;
        grid-template-columns: 1fr;
        justify-content: center;
        align-items: center;
    }
    .hero-left{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        vertical-align: middle;
        margin-right: 0;
    }
    .homeheader{
        font-family: 'ZCOOL XiaoWei', sans-serif;
        font-size: 32px;
        line-height: 3.0rem;
        max-width: 400px;
    }
    .hero-right img{
        display: none;
    }
    .direction{
        font-size: 1rem;
        font-weight: 300;
        justify-content: center;
    }
    .languages{
        grid-template-columns: repeat(4, minmax(50px, 1fr));
    }

    .to-casestudy p{
        font-size: 15px;
        padding-right: 10px;
    }

    .project{
        display: flex;
        flex-direction: column;
        border-radius: 5px;
        padding: 0;
        width: 350px;
        max-width: 500px;
    }
    .project-title{
        font-size: 20px;
    }
    .project-brief{
        font-size: 14px;
    }
    .button-text{
        font-size: 14px;
    }
}
